import request from '@/utils/request'

export function getGoodsDataById(data) {
  return request({
    url: '/goods/getgoods',
    method: 'post',
    data
  })
}


export function getGoodsItem(id) {
  return request({
    url: '/goods/getitem',
    method: 'post',
    data:{id:id}
  })
}
