<template>
  <div class="goods">
    <van-swipe class="goods-swipe" :autoplay="3000">
      <van-swipe-item v-for="thumb in goods.thumb" :key="thumb">
        <img :src="thumb" />
      </van-swipe-item>
    </van-swipe>

    <van-cell-group>
      <van-cell>
        <div class="goods-title">{{ goods.title }}</div>
      </van-cell>
    </van-cell-group>

    <van-cell-group class="goods-cell-group">
      <template #title>
        <div class="van-cell__label">
          {{ goods.tag1 }}
        </div>
        <div class="van-cell__label">
          {{ goods.tag2 }}
        </div>
        <div class="van-cell__label">
          {{ goods.tag3 }}
        </div>
        <div class="van-cell__label">
          {{ goods.tag4 }}
        </div>
        <div class="van-cell__label">
          {{ goods.tag5 }}
        </div>
        <div class="van-cell__label">
          {{ goods.tag6 }}
        </div>
      </template>
    </van-cell-group>

    <div class="desc">
      <p v-html="goods.desc"></p>
    </div>
    <siteabbar :idx="1" />
  </div>
</template>

<script>
import { Tag, Col, Icon, Cell, CellGroup, Swipe, SwipeItem } from "vant";

import { useStore, mapState } from "vuex";
import { useRoute } from "vue-router";
import { reactive, onMounted, toRefs } from "vue";
import { getGoodsItem } from "@/api/goods";
import Siteabbar from "../components/Sitetabbar";

export default {
  components: {
    [Siteabbar.name]: Siteabbar,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      goods: [],
    });
    const route = useRoute();
    onMounted(async () => {
      const { id } = route.query;
      const { data } = await getGoodsItem(id);
      state.goods = {
        id: data.id,
        title: data.title,
        desc: data.desc,
        tag1: data.tag1,
        tag2: data.tag2,
        tag3: data.tag3,
        tag4: data.tag4,
        tag5: data.tag5,
        tag6: data.tag6,
        thumb: data.thumb,
      };
    });
    return {
      ...toRefs(state),
      store,
    };
  },
  computed: {
    ...mapState("cart", ["cart"]),
  },

  methods: {
    goWhatsapp() {
      window.location.href = "https://api.whatsapp.com/send?phone=86123456789";
    },
    goWechat() {
      window.location.href = "whatsapp://send?phone=+86123456789&text=Hello";
    },
    goPhone() {
      window.location.href = "tel:1234567890";
    },
  },
};
</script> 

<style lang="less" scoped>
.desc {
  padding: 0 20px;
}
.desc > p {
  color: #333;
  font-size: 16px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.goods {
  padding-bottom: 50px;

  &-swipe {
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
}
.van-action-bar-icon {
  width: 80px;
}
</style>
